import {Build} from "../../server";
import React, {Component} from "react";

const TEAMCITY_HOST_URL = "https://teamcity.teamdev-integration.dev";
const GITHUB_PLATINUM_URL = "https://github.com/TeamDev-IP/Platinum";


const teamcityBuildLink = (metadata: Build) => {
    const buildId = metadata["ciBuild"];
    const configurationName = metadata["ciConfiguration"];
    return `${TEAMCITY_HOST_URL}/viewLog.html?buildId=${buildId}&buildTypeId=${configurationName}`;
};

const githubCommitLink = (metadata: Build) => {
    const commit = metadata["commit"];
    return `${GITHUB_PLATINUM_URL}/commit/${commit}`;
};

/**
 * @param metadata an object containing info about a Platinum build (commit, platform, etc.)
 * @returns {JSX.Element} a component containing info about a Platinum build
 * @constructor
 */
type MetadataProps = { metadata: Build }

export class Metadata extends Component<MetadataProps, unknown> {
    private linkStyle = {marginLeft: 5};

    constructor(props: MetadataProps) {
        super(props);
    }

    render() {
        return (
            <div>
                {this.props.metadata &&
                    <div>
                        <p><b>Platform:</b> {this.props.metadata["platform"]}</p>
                        <p><b>Architecture:</b> {this.props.metadata["architecture"]}</p>
                        <p><b>TeamCity Build Link:</b>
                            <a style={this.linkStyle} target="_blank"
                               href={teamcityBuildLink(this.props.metadata)} rel="noreferrer">
                                Click
                            </a>
                        </p>
                        <p><b>GitHub Commit Link:</b>
                            <a style={this.linkStyle} target="_blank"
                               href={githubCommitLink(this.props.metadata)} rel="noreferrer">
                                Click
                            </a>
                        </p>
                    </div>
                }
            </div>
        );
    }
}
