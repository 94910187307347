/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Build } from '../models/Build';
import type { DumpFiles } from '../models/DumpFiles';
import type { DumpIds } from '../models/DumpIds';
import type { ErrorResponse } from '../models/ErrorResponse';
import type { MetadataPage } from '../models/MetadataPage';
import type { Stacktrace } from '../models/Stacktrace';
import type { StacktracesPage } from '../models/StacktracesPage';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class DefaultService {

    /**
     * Saves the metadata and returns a signed URL for uploading the symbols
     * @param formData
     * @returns string A signed URL to upload the symbols file using PUT.
     * @throws ApiError
     */
    public static serverSymbolsUpload(
        formData?: Build,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/symbols/upload',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * Starts analyzing the given dump files
     * @param formData
     * @returns DumpIds Extraction started
     * @returns ErrorResponse Unexpected error
     * @throws ApiError
     */
    public static serverDumpAnalyze(
        formData?: DumpFiles,
    ): CancelablePromise<DumpIds | ErrorResponse> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/dump/analyze',
            formData: formData,
            mediaType: 'multipart/form-data',
        });
    }

    /**
     * Obtains stacktraces by a comma-separated list of IDs
     * @param dumpIds
     * @returns Stacktrace The stacktrace is found
     * @throws ApiError
     */
    public static serverStacktraceGet(
        dumpIds: string,
    ): CancelablePromise<Array<Stacktrace>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/stacktrace/{dump_ids}',
            path: {
                'dump_ids': dumpIds,
            },
            errors: {
                404: `The stacktrace is not found`,
            },
        });
    }

    /**
     * Obtains stacktraces by the user ID
     * @param userId
     * @param currentPage
     * @param itemsPerPage
     * @returns StacktracesPage The stacktraces are found
     * @throws ApiError
     */
    public static serverStacktraceGetAll(
        userId: string,
        currentPage: number,
        itemsPerPage: number,
    ): CancelablePromise<StacktracesPage> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/stacktraces/{user_id}',
            path: {
                'user_id': userId,
            },
            query: {
                'current_page': currentPage,
                'items_per_page': itemsPerPage,
            },
            errors: {
                404: `The stacktraces are not found`,
            },
        });
    }

    /**
     * Obtains all available metadata
     * @returns MetadataPage All metadata
     * @throws ApiError
     */
    public static serverMetadataGetAll(): CancelablePromise<MetadataPage> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/metadata',
        });
    }

}
