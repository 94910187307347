/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type Build = {
    /**
     * The commit hash of the release
     */
    commit?: string;
    /**
     * The TeamCity build ID
     */
    ciBuild?: string;
    /**
     * The TeamCity configuration name
     */
    ciConfiguration?: string;
    /**
     * The architecture of the machine
     */
    architecture?: Build.architecture;
    /**
     * The operating system of the machine
     */
    platform?: Build.platform;
};

export namespace Build {

    /**
     * The architecture of the machine
     */
    export enum architecture {
        X64 = 'x64',
        X86 = 'x86',
        ARM64 = 'arm64',
    }

    /**
     * The operating system of the machine
     */
    export enum platform {
        MAC = 'mac',
        LINUX = 'linux',
        WINDOWS = 'windows',
    }


}

