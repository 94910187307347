import {BrowserRouter, Route, Routes} from "react-router-dom";
import {DumpUploadForm} from "./components/dump-upload-form";
import {NotFoundPage} from "./components/404";
import React, {useContext, useEffect} from "react";
import {StackTrace} from "./components/stacktrace";
import {AuthContext} from "./index";
import {useAuthState} from "react-firebase-hooks/auth";
import {GoogleAuthProvider, signInWithPopup, User} from "firebase/auth";
import {Loader} from "./components/loader";
import {Container, Row} from "react-bootstrap";
import {SideBar} from "./components/sidebar";
import Col from "react-bootstrap/Col";

/**
 * The main application component containing routes for all application-level components.
 *
 * <p>The component provides the logic for authentication and handling common errors.
 *
 * @constructor
 */
function App() {
    const PERMITTED_EMAIL_DOMAIN = "teamdev.com";

    const auth = useContext(AuthContext);
    const [user, isAuthenticationInProcess] = useAuthState(auth);
    const isUserPermitted = (user: User | undefined | null) => user && user.email?.endsWith(PERMITTED_EMAIL_DOMAIN);
    useEffect(function() {
        const signIn = async () => {
            if (!isAuthenticationInProcess && !isUserPermitted(user)) {
                const provider = new GoogleAuthProvider();
                try {
                    await signInWithPopup(auth, provider);
                } catch (ignored) {
                    // Do nothing
                }
            }
        };
        signIn().then(() => console.log("Signed in"), () => console.log("Failed to sign in"));
    }, [isAuthenticationInProcess, user]);

    return (
        <div>
            {isAuthenticationInProcess
                ? <Loader/>
                : <>
                    {isUserPermitted(user) &&
                        <BrowserRouter>
                            <Row>
                                <Col sm={2}>
                                    <SideBar/>
                                </Col>
                                <Col sm={10}>
                                    <div style={{height: "100vh", overflow: "hidden", display: "flex"}}>
                                        <div style={{flex: 1, overflow: "auto"}}>
                                            <Container style={{marginTop: 50}}>
                                                <Routes>
                                                    <Route path="/" element={<DumpUploadForm/>}/>
                                                    <Route path={"/stacktrace/:ids"}
                                                           element={<StackTrace/>}/>
                                                    <Route path="*" element={<NotFoundPage/>}/>
                                                </Routes>
                                            </Container>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        </BrowserRouter>
                    }
                </>
            }
        </div>
    );
}

export default App;
